import React from "react"
import PropTypes from "prop-types"
import * as S from "./gatsby-bg-image.styles"
import { GatsbyImage } from "gatsby-plugin-image"

const GatsbyBgImage = props => {
  const { img, imgPos, mobileImg, mobileImgPos, tag, alt, fallback, children, ...otherProps } = props

  let image = ""
  let mobileImage = ""

  if (img && img.localFile) {
    image = img.localFile.childImageSharp.gatsbyImageData
  }

  if (img && img.childImageSharp) {
    image = img.childImageSharp.gatsbyImageData
  }

  if (mobileImg && mobileImg.localFile) {
    mobileImage = mobileImg.localFile.childImageSharp.gatsbyImageData
  }

  if (mobileImg && mobileImg.childImageSharp) {
    mobileImage = mobileImg.childImageSharp.gatsbyImageData
  }

  if (!img && fallback) {
    if (fallback.localFile) {
      image = img.localFile.childImageSharp.gatsbyImageData
    }
    if (fallback.childImageSharp) {
      image = fallback.childImageSharp.gatsbyImageData
    }
  }

  return (
    <S.Wrapper component={tag ? tag : "section"} {...otherProps}>
      {image && <GatsbyImage alt="" image={image} className="desktop" role="presentation" objectPosition={imgPos} />}
      {mobileImage && <GatsbyImage alt="" image={mobileImage} className="mobile" role="presentation" objectPosition={mobileImgPos} />}
      {children}
    </S.Wrapper>
  )
}

GatsbyBgImage.propTypes = {
  img: PropTypes.object.isRequired,
  tag: PropTypes.string,
  imgPos: PropTypes.string,
  mobileImgPos: PropTypes.string,
}

export default GatsbyBgImage
