import styled from "styled-components"
import { Box } from "@material-ui/core"

export const Wrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  z-index: 1;

  .mobile {
    display: none;
  }

  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
    z-index: -1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    img { object-position: bottom; }
  }

  ${({ theme }) => theme.breakpoints.down("sm")}{
      .desktop + .mobile {
        display: block;
        
        img {
          object-position: top;
        }

      }
  }
`
