import styled from "styled-components"
import GatsbyBgImage from "../../components/gatsby-bg-image/gatsby-bg-image.component"
import RoundedButton from "../../components/rounded-button/rounded-button.component"

export const Wrapper = styled(GatsbyBgImage)`
  height: 100vh;
  padding-top: 10%;
  h1 {
      font-size: 6.4rem;
      line-height: 1em;
  }
  h1,
  h1 p {
    font-family: ${({ theme }) => theme.fonts.primary};
    max-width: 650px;
    margin: 0;
  }
  h2 {
    max-width: 350px;
  }
  p {
    max-width: 550px;
    font-weight: 500;
    font-size: 1.25em;
  }
  ${({ theme }) => theme.breakpoints.only("md")} {
    h1 {
      font-size: 5.4rem;
      max-width: 500px;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 615px;
    h1 {
      font-size: 4.8rem;
    }
    h1,
    h1 p {
      max-width: 350px;
    }
    h2 {
      max-width: 300px;
    }
    p {
      max-width: 280px;
    }
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    height: 415px;
    padding-top: 25%;
    h1 {
      font-size: 3rem;
    }
    h1,
    h1 p {
      max-width: 200px;
    }
    h2 {
      max-width: 250px;
    }
    p {
      max-width: 220px;
    }
  }

  @media only screen and (max-width: 480px){
    h1 {
      font-size: clamp(2rem, 11vw, 3rem);
    }
  }

  position: relative;

  .secondary {
    max-width: 500px;
    position: absolute;
    right: 0;
    bottom: 15vh;

    sup { position: static; }

    .title {
      font-size: 4rem;
      font-weight: bold;
      line-height: 1em;
    }

    .subtitle {
      font-size: 3rem;
      font-weight: 400;
      font-family: ${({ theme }) => theme.fonts.secondary};
      line-height: 1em;
    }

    .content p {
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts.primary};
      text-transform: uppercase;
    }
  }
`

export const Button = styled(RoundedButton)`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`