import React from "react"
import * as S from "./hero-banner.styles.jsx"
import Container from "@material-ui/core/Container"
import parse from "html-react-parser"

const HeroBanner = ({ title, backgroundImage, backgroundImagePosition, backgroundImageMobile, backgroundImageMobilePosition, content, subtitle, secondaryLayout, titleH2, height, button }) => {
  const heroHeight = height ? height : '100vh';

  return (
    <S.Wrapper id="large" img={backgroundImage} imgPos={backgroundImagePosition} mobileImg={backgroundImageMobile} mobileImgPos={backgroundImageMobilePosition} heroHeight={heroHeight} alt="" style={{ height: heroHeight }}>
      <Container className={secondaryLayout ? 'secondary' : 'primary'}>
        {!secondaryLayout && title && <h1 className="title">{parse(title)}</h1>}

        {secondaryLayout && titleH2 && <h2 className="title">{parse(titleH2)}</h2>}
        {subtitle && <h3 className="subtitle">{parse(subtitle)}</h3>}
        {content && <p className="content">{parse(content)}</p>}
        {button && <S.Button url={button.url} target={button.target}>{button.title}</S.Button>}
      </Container>
    </S.Wrapper>
  )
}
export default HeroBanner
